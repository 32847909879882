import React, { useState, useEffect } from "react";
import { AutoComplete, message, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./NavSearch.css"; // Import the CSS file for animations

const NavSearch = () => {
  const [options, setOptions] = useState([]);
  const [data, setData] = useState([]); // Property data
  const [loading, setLoading] = useState(true); // Loading state
  const [isFocused, setIsFocused] = useState(false); // Input focus state
  const baseUrl = process.env.REACT_APP_SERVER_URL; // Base URL
  const navigate = useNavigate(); // React router navigation

  // Fetch properties on component mount
  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const response = await axios.get(`${baseUrl}/properties`, {
          headers: {
            "x-api-key": process.env.REACT_APP_API_KEY,
          },
        });
        setData(response.data);
      } catch (error) {
        console.error("Error fetching properties:", error);
        message.error("Failed to load properties. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchProperties();
  }, [baseUrl]);

  // Search for properties by name
  const searchProperty = (query) => {
    if (!query) return [];
    return data
      .filter((property) =>
        property.name.toLowerCase().includes(query.toLowerCase()),
      )
      .map((property) => ({
        value: property.name,
        label: (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>{property.name}</span>
          </div>
        ),
      }));
  };

  // Handle search input changes
  const handleSearch = (value) => {
    const results = searchProperty(value);
    setOptions(
      results.length ? results : [{ value: "", label: "No results found" }],
    );
  };

  // Handle selection from AutoComplete dropdown
  const onSelect = (value) => {
    const selectedProperty = data.find((property) => property.name === value);
    if (selectedProperty) {
      const resultsPage = `/results?name=${encodeURIComponent(
        selectedProperty.name,
      )}`;

      // Check if currently on the /results page
      if (window.location.pathname === "/results") {
        // Refresh the page if already on /results
        window.location.reload();
      } else {
        // Otherwise, navigate to the results page
        navigate(resultsPage);
      }
    } else {
      console.log("No property selected");
    }
  };

  return (
    <>
      {loading ? (
        <Spin style={{ marginLeft: "7.5%", marginTop: "10px" }} />
      ) : (
        <AutoComplete
          popupMatchSelectWidth={252}
          style={{ width: 220 }}
          options={options}
          onSelect={onSelect}
          placeholder="Search for a Property Name"
          onSearch={handleSearch}
          size="large"
          bordered={false}
          // Add the blinking class conditionally
          className={isFocused ? "blinking" : ""}
          // Track focus and blur events
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
      )}
    </>
  );
};

export default NavSearch;
