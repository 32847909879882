import React from "react";
import {
  Routes,
  Route,
  useLocation,
  Navigate,
  useParams,
} from "react-router-dom";
import { useAuthContext } from "library/hooks/useAuthContext"; // Adjusted import
import Layout from "containers/Layout/Layout";
import Loader from "components/Loader/Loader";
import {
  HOME_PAGE,
  LISTING_POSTS_PAGE,
  BLOGS_PAGE,
  SINGLE_POST_PAGE,
  AGENT_PROFILE_PAGE,
  AGENT_PROFILE_FAVORITE,
  AGENT_PROFILE_CONTACT,
  PRICING_PLAN_PAGE,
  PRIVACY_PAGE,
  LOGIN_PAGE,
  REGISTRATION_PAGE,
  FORGET_PASSWORD_PAGE,
  ADD_HOTEL_PAGE,
  AGENT_IMAGE_EDIT_PAGE,
  AGENT_PASSWORD_CHANGE_PAGE,
  AGENT_ACCOUNT_SETTINGS_PAGE,
  VERIFY_EMAIL_PAGE,
  LOCATIONS_PAGE,
} from "./settings/constant";

// Protected route
function RequireAuth({ children }) {
  const { user } = useAuthContext(); // Using the custom hook
  const location = useLocation();

  if (!user) {
    return <Navigate to={LOGIN_PAGE} state={{ from: location }} />;
  }

  return children;
}

// Lazy loaded components
const HomePage = React.lazy(() => import("containers/Home/Home"));
const ListingPage = React.lazy(() => import("containers/Listing/Listing"));
const SinglePageView = React.lazy(
  () => import("containers/SinglePage/SinglePageView"),
);

const BlogsPage = React.lazy(() => import("containers/Blog/Blog"));

const ComparisonPage = React.lazy(
  () => import("containers/Comparison/Comparison"),
);
const AgentDetailsViewPage = React.lazy(
  () => import("containers/Agent/AccountDetails/AgentDetailsViewPage"),
);
const AgentItemLists = React.lazy(
  () => import("containers/Agent/AccountDetails/AgentItemLists"),
);
const AgentFavItemLists = React.lazy(
  () => import("containers/Agent/AccountDetails/AgentFavItemLists"),
);
const AgentContact = React.lazy(
  () => import("containers/Agent/AccountDetails/AgentContact"),
);
const PricingPage = React.lazy(() => import("containers/Pricing/Pricing"));
const PrivacyPage = React.lazy(() => import("containers/Privacy/Privacy"));
const SignInPage = React.lazy(() => import("containers/Auth/SignIn/SignIn"));
const SignUpPage = React.lazy(() => import("containers/Auth/SignUp/SignUp"));
const ForgetPasswordPage = React.lazy(
  () => import("containers/Auth/ForgetPassword"),
);
const VerifyEmailPage = React.lazy(
  () => import("containers/Auth/TwoStep/AuthTwoSteps"),
);
const NotFound = React.lazy(() => import("containers/404/404"));
const AddListingPage = React.lazy(
  () => import("containers/AddListing/AddListing"),
);
const AgentAccountSettingsPage = React.lazy(
  () => import("containers/Agent/AccountSettings/AgentAccountSettingsPage"),
);
const AgentCreateOrUpdateForm = React.lazy(
  () => import("containers/Agent/AccountSettings/AgentCreateOrUpdateForm"),
);
const AgentPictureChangeForm = React.lazy(
  () => import("containers/Agent/AccountSettings/AgentPictureChangeForm"),
);
const ChangePassWord = React.lazy(
  () => import("containers/Agent/AccountSettings/ChangePassWordForm"),
);
const LocationsPage = React.lazy(
  () => import("containers/Home/Location/LocationsPage"),
);

function PropertyRedirect() {
  const { propertyId, propertyName } = useParams();

  if (propertyId && propertyName) {
    const newSlug = `${propertyName}-${propertyId}`;
    return <Navigate to={`/properties/${newSlug}`} replace />;
  }

  // Optional fallback for invalid params
  return <Navigate to="/" replace />;
}

export default function AppRoutes() {
  const { user } = useAuthContext(); // Using the custom hook

  return (
    <Routes>
      <Route path={HOME_PAGE} element={<Layout />}>
        <Route
          index
          element={
            <React.Suspense fallback={<Loader />}>
              <HomePage />
            </React.Suspense>
          }
        />
        <Route
          path={LISTING_POSTS_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <ListingPage />
            </React.Suspense>
          }
        />
          <Route
          path={BLOGS_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <BlogsPage />
            </React.Suspense>
          }
        />
        <Route
          path="/property-comparison-tool/:propertyId"
          element={
            <React.Suspense fallback={<Loader />}>
              <ComparisonPage />
            </React.Suspense>
          }
        />

        <Route
          path="/properties/:propertyId/:propertyName"
          element={
            <React.Suspense fallback={<Loader />}>
              <PropertyRedirect />
            </React.Suspense>
          }
        />

        <Route
          path={SINGLE_POST_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <SinglePageView />
            </React.Suspense>
          }
        />

        <Route
          path={LOCATIONS_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <LocationsPage />
            </React.Suspense>
          }
        />
        {/* End of Nested routes */}
        <Route
          path={PRICING_PLAN_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <PricingPage />
            </React.Suspense>
          }
        />
        <Route
          path={PRIVACY_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <PrivacyPage />
            </React.Suspense>
          }
        />
        <Route
          path={LOGIN_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <SignInPage />
            </React.Suspense>
          }
        />
        <Route
          path={REGISTRATION_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <SignUpPage />
            </React.Suspense>
          }
        />
        <Route
          path={FORGET_PASSWORD_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <ForgetPasswordPage />
            </React.Suspense>
          }
        />
        <Route
          path={VERIFY_EMAIL_PAGE}
          element={
            <React.Suspense fallback={<Loader />}>
              <VerifyEmailPage />
            </React.Suspense>
          }
        />
        {/* Protected routes */}

        {/* Nested routes for agent page */}
        <Route
          path={AGENT_PROFILE_PAGE}
          element={
            <RequireAuth>
              <React.Suspense fallback={<Loader />}>
                <AgentDetailsViewPage user={user} />{" "}
              </React.Suspense>
            </RequireAuth>
          }
        >
          <Route
            index
            element={
              <RequireAuth>
                <React.Suspense fallback={<Loader />}>
                  <AgentItemLists />
                </React.Suspense>
              </RequireAuth>
            }
          />
          <Route
            path={AGENT_PROFILE_FAVORITE}
            element={
              <RequireAuth>
                <React.Suspense fallback={<Loader />}>
                  <AgentFavItemLists />
                </React.Suspense>
              </RequireAuth>
            }
          />
          <Route
            path={AGENT_PROFILE_CONTACT}
            element={
              <RequireAuth>
                <React.Suspense fallback={<Loader />}>
                  <AgentContact />
                </React.Suspense>
              </RequireAuth>
            }
          />
        </Route>

        <Route
          path={ADD_HOTEL_PAGE}
          element={
            <RequireAuth>
              <React.Suspense fallback={<Loader />}>
                <AddListingPage />
              </React.Suspense>
            </RequireAuth>
          }
        />
        <Route
          path={AGENT_ACCOUNT_SETTINGS_PAGE}
          element={
            <RequireAuth>
              <React.Suspense fallback={<Loader />}>
                <AgentAccountSettingsPage />
              </React.Suspense>
            </RequireAuth>
          }
        >
          <Route
            index
            element={
              <React.Suspense fallback={<Loader />}>
                <AgentCreateOrUpdateForm />
              </React.Suspense>
            }
          />
          <Route
            path={AGENT_IMAGE_EDIT_PAGE}
            element={
              <React.Suspense fallback={<Loader />}>
                <AgentPictureChangeForm />
              </React.Suspense>
            }
          />
          <Route
            path={AGENT_PASSWORD_CHANGE_PAGE}
            element={
              <React.Suspense fallback={<Loader />}>
                <ChangePassWord />
              </React.Suspense>
            }
          />
        </Route>
        {/* End of Protected routes */}
        <Route
          path="*"
          element={
            <React.Suspense fallback={<Loader />}>
              <NotFound />
            </React.Suspense>
          }
        />
      </Route>
    </Routes>
  );
}
