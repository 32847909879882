import { NavLink } from "react-router-dom";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import useOnClickOutside from "library/hooks/useOnClickOutside";
import { Menu } from "antd";
import { HOME_PAGE, AGENT_ACCOUNT_SETTINGS_PAGE } from "settings/constant";
import useLogout from "library/hooks/useLogout";

const MobileMenu = ({ user, className }) => {
  const { logout } = useLogout();
  const navigate = useNavigate();

  const dropdownRef = useRef(null);
  useOnClickOutside(dropdownRef, () => {});

  // Handle logout and redirect to home page
  const handleLogout = () => {
    logout();
    navigate(HOME_PAGE, { replace: true });
  };

  // Define navigation items
  const navigationItems = [
    // { label: 'Hotels', path: HOME_PAGE },
    // { label: 'Listing', path: LISTING_POSTS_PAGE },
    // { label: 'Pricing', path: PRICING_PLAN_PAGE },
  ];

  // Add account settings and logout if user is logged in
  if (user) {
    navigationItems.push(
      { label: "Account Settings", path: AGENT_ACCOUNT_SETTINGS_PAGE },
      { label: `Credits: ${user.credits} ` },

      { label: "Log Out", action: handleLogout },
    );
  }

  // Create menu items for rendering
  const menuItems = navigationItems.map(({ label, path, action }) => ({
    key: label.toLowerCase().replace(" ", "_"),
    label: path ? (
      <NavLink to={path}>{label}</NavLink>
    ) : (
      <button
        onClick={action}
        style={{
          border: "none",
          background: "transparent",
          cursor: "pointer",
          padding: 0,
          fontSize: "inherit",
        }}
      >
        {label}
      </button>
    ),
  }));

  return (
    <div ref={dropdownRef}>
      <Menu className={className} items={menuItems} />
    </div>
  );
};

export default MobileMenu;
