import React, { useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import Sticky from "react-stickynode";
import { IoIosClose } from "react-icons/io";
import { Button, Drawer } from "antd";
import Logo from "components/UI/Logo/Logo";
import Text from "components/UI/Text/Text";
import TextLink from "components/UI/TextLink/TextLink";
import Navbar from "components/Navbar/Navbar";
import { useAuthContext } from "library/hooks/useAuthContext";
import { LayoutContext } from "context/LayoutProvider";
import useWindowSize from "library/hooks/useWindowSize";
import { AGENT_PROFILE_PAGE } from "settings/constant";
import AuthMenu from "./AuthMenu";
import MainMenu from "./MainMenu";
import MobileMenu from "./MobileMenu";
import ProfileMenu from "./ProfileMenu";
import NavbarSearch from "./NavbarSearch";
import LogoIcon from "./LogoIcon";
import HeaderWrapper, {
  MobileNavbar,
  CloseDrawer,
  AvatarWrapper,
  AvatarImage,
  AvatarInfo,
  LogoArea,
} from "./Header.style";

export default function Header() {
  let location = useLocation();
  const [{ searchVisibility }] = useContext(LayoutContext);
  const { user } = useAuthContext(); // Get user from AuthContext
  const avatarImg = user?.profileImg;
  const { width } = useWindowSize();
  const [state, setState] = useState(false);

  const sidebarHandler = () => {
    setState(!state);
  };

  const headerType = location.pathname === "/" ? "transparent" : "default";

  return (
    <HeaderWrapper>
      <Sticky
        top={headerType === "transparent" ? -1 : 0}
        innerZ={10001}
        activeClass="isHeaderSticky"
      >
        {width > 991 ? (
          <Navbar
            logo={
              <>
                {headerType === "transparent" && <LogoIcon />}
                <Logo
                  withLink
                  linkTo="/"
                  src="/images/Logo_Light.svg"
                  title="CityDwelling."
                />
              </>
            }
            navMenu={<MainMenu />}
            authMenu={<AuthMenu />}
            isLogin={Boolean(user)} // Check if user exists
            avatar={<Logo src={avatarImg} />}
            profileMenu={
              <ProfileMenu user={user} avatar={<Logo src={avatarImg} />} />
            }
            headerType={headerType}
            searchComponent={<NavbarSearch />}
            location={location}
            searchVisibility={searchVisibility}
          />
        ) : (
          <MobileNavbar className={headerType}>
            <LogoArea>
              <>
                <Logo
                  withLink
                  linkTo="/"
                  src="/images/Logo_Light.svg"
                  title="CityDwelling."
                />
              </>
              <NavbarSearch />
            </LogoArea>
            <Button
              className={`hamburg-btn ${state ? "active" : ""}`}
              onClick={sidebarHandler}
            >
              <span />
              <span />
              <span />
            </Button>
            <Drawer
              placement="right"
              closable={false}
              onClose={sidebarHandler}
              width="285px"
              className="mobile-header"
              open={state}
            >
              <CloseDrawer>
                <button onClick={sidebarHandler}>
                  <IoIosClose />
                </button>
              </CloseDrawer>
              {user ? ( // Check if user exists
                <AvatarWrapper>
                  <AvatarImage>
                    <Logo src={avatarImg} />
                  </AvatarImage>
                  <AvatarInfo>
                    <Text as="h3" content={user.firstName || "User"} />{" "}
                    {/* Display user's name */}
                    <TextLink
                      link={AGENT_PROFILE_PAGE}
                      content="View Profile"
                    />
                  </AvatarInfo>
                </AvatarWrapper>
              ) : (
                <AuthMenu className="auth-menu" />
              )}
              <MobileMenu user={user} className="main-menu" />
            </Drawer>
          </MobileNavbar>
        )}
      </Sticky>
    </HeaderWrapper>
  );
}
