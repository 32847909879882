import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  fontFamily,
  fontWeight,
  textAlign,
  lineHeight,
  letterSpacing,
} from "styled-system";
import { base, themed } from "../Base";

const LinkWrapper = styled(Link)`
  ${base}
  ${fontFamily}
  ${fontWeight}
  ${textAlign}
  ${lineHeight}
  ${letterSpacing}
  ${themed("TextLink")}
  
  text-decoration: underline; /* Ensures all TextLinks are underlined */
`;

const TextLink = ({ link, openInNewTab,icon, content, ...props }) => {
  if (openInNewTab) {
    return (
      <LinkWrapper
        as="a" // Render as a regular anchor tag
        href={link}
        target="_blank"
        rel="noopener noreferrer" // Prevent security vulnerabilities
        {...props}
      >
        {icon} {content}
      </LinkWrapper>
    );
  }

  return (
    <LinkWrapper to={link} {...props}>
      {icon} {content}
    </LinkWrapper>
  );
};

TextLink.propTypes = {
  /** Add here link path. */
  link: PropTypes.string,
  /** Add here your content Or any component. */
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  fontFamily: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ),
  ]),
  fontWeight: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ),
  ]),
  textAlign: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ),
  ]),
  lineHeight: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ),
  ]),
  letterSpacing: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ),
  ]),
  ...base.propTypes,
};

export default TextLink;
