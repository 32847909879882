import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  fontFamily,
  fontWeight,
  textAlign,
  lineHeight,
  letterSpacing,
} from "styled-system";
import { base, themed } from "../Base";

const TextWrapper = styled("p")(
  {
    marginBottom: "10px", // Adjust the gap size here
  },
  base,
  fontFamily,
  fontWeight,
  textAlign,
  lineHeight,
  letterSpacing,
  themed("Text"),
);

const Text = ({ content, ...props }) => {
  const paragraphs = content
    .split("\n")
    .filter((paragraph) => paragraph.trim().length > 0);

  return (
    <>
      {paragraphs.map((paragraph, index) => (
        <TextWrapper key={index} {...props}>
          {paragraph}
        </TextWrapper>
      ))}
    </>
  );
};

Text.propTypes = {
  content: PropTypes.string,
  as: PropTypes.string,
  fontFamily: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ),
  ]),
  fontWeight: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ),
  ]),
  textAlign: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ),
  ]),
  lineHeight: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ),
  ]),
  letterSpacing: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ),
  ]),
  ...base.propTypes,
};

Text.defaultProps = {
  as: "p",
  m: 0,
};

export default Text;
